import ContainerMain from "src/pages/common/ContainerMain"
import "./WhyOcto.scss"
import { useFormatter } from "helpers/i18n"

export default function WhyOcto({ list, title }) {
  const { __ } = useFormatter()
  return (
    <>
      <div className="NSBVgsiILr">
        <ContainerMain>
          <div className="KizWpWHnNn">
            <div className="section-title mUIjNuGqBU">
              {title ||
                `${__({
                  defaultMessage: "Why OctoSells?",
                })}`}
            </div>
            <div className="WpBqTKomku">
              {list
                ? list.map((item, key) => {
                    return (
                      <div className="kzsRZGbSsj" key={key}>
                        <div className="jNAByrdmhI">
                          <img
                            alt=""
                            className="LlmQKyZrhD"
                            src={item?.image}
                          />
                        </div>
                        <div
                          className="ezccLxmJOK"
                          dangerouslySetInnerHTML={{
                            __html: item?.title,
                          }}
                        ></div>
                        <div className="hpTbGUgSMG">{item.description}</div>
                      </div>
                    )
                  })
                : null}
            </div>
          </div>
        </ContainerMain>
      </div>
    </>
  )
}
